import dayjs from "dayjs";
import { Link } from "react-router-dom";
import "dayjs/locale/fr";
import { useTranslation } from "react-i18next";

import Status from "common/components/Status/Status";
import DatesRange from "common/components/DatesRange/DatesRange";
import Button from "common/components/Button/Button";
import truncateString from "common/utils/truncateString";
import getCommentsNumber from "common/utils/getCommentsNumber";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import { Post } from "types/Post";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import getLastComment from "common/utils/getLastComment";
import PublicationIcon from "common/components/PostsAndActivities/PublicationIcon";
import useAuth from "common/hooks/useAuth";
import Icon from "components/Icon/Icon";
import checkRights from "common/utils/checkRights";
import ContactGroups from "common/components/ContactGroups/ContactGroups";
import { ContactGroup } from "types/ContactGroup";
import CommentItemSmall from "common/components/CommentItem/CommentItemSmall";
import getWording from "common/utils/wording";
import getFlattenText from "common/utils/getFlattenText";
import DropDown from "common/components/DropDown/DropDown";

const iconStyle = "w-4 h-4 text-white";

const containerStyle =
  "flex px-4 py-4 mb-2 w-full relative bg-white cursor-pointer rounded-lg border border-slate-150 transition-all";

function PostItem({
  item,
  onDelete,
  onNavigate,
  onOpenThreads,
  onDuplicate,
  onPin,
  onResendNotification,
}: any) {
  const {
    id,
    type,
    title,
    content,
    contentRich,
    publishedDate,
    startEventDate,
    endEventDate,
    mainImage,
    privateThread,
    status,
    upVotes,
    site,
    isPublic,
    contactGroups = [],
    author,
    pinned,
  } = item as Post;

  const { user: currentUser } = useAuth();

  const { t } = useTranslation();

  const canManageItem =
    checkRights(currentUser, "canManagePost", [item.site?.id], ["isAdmin"]) ||
    (checkRights(currentUser, "canSubmitPost", [item.site?.id], ["isAdmin"]) &&
      currentUser?.id === author?.id &&
      status === "pending_moderation");

  const lastComment = privateThread ? getLastComment([privateThread]) : null;
  const commentsNumber = privateThread
    ? getCommentsNumber([privateThread])
    : null;

  const contactGroupsPublic = isPublic
    ? [
        {
          name: t("common.public"),
          description: t("common.publicDesc", {
            wording: getWording(true),
          }),
          type: "public",
          isSpecial: true,
        } as ContactGroup,
      ]
    : [];

  const detailLink = `/posts/${id}`;

  return (
    <div
      className={`${containerStyle}  items-start lg:items-stretch flex-col lg:flex-row`}
    >
      <Link to={detailLink} className="w-full">
        <div className="responsive-flex-lg mb-4 lg:mb-0 w-full gap-2 lg:gap-4 pr:0 lg:pr-4">
          <div className="flex relative w-full h-40 lg:w-16 lg:h-16 xl:w-24 xl:h-24 shrink-0">
            <div className="absolute -top-2 -left-2">
              <PublicationIcon type={type} iconStyle={iconStyle} />
            </div>
            <div className="rounded-lg overflow-hidden w-full">
              <ImageComponent
                image={
                  mainImage ? ImageFromStrapiMedia(mainImage)?.uri : undefined
                }
              />
            </div>
          </div>
          <div className="flex flex-col flex-1 justify-start overflow-hidden">
            {/* head */}
            <div className="flex flex-col xl:flex-row w-full gap-4">
              <div className="flex flex-col flex-1">
                <h4 className="text-xl font-bold text-primary leading-6">
                  {title}
                </h4>
              </div>

              {(startEventDate || endEventDate) && (
                <div className="flex justify-end items-start mb-2 lg:mb-0">
                  <DatesRange
                    startDate={dayjs(startEventDate).toDate()}
                    endDate={
                      endEventDate ? dayjs(endEventDate).toDate() : undefined
                    }
                    isCurrentDate={
                      dayjs().isBetween(
                        dayjs(startEventDate),
                        dayjs(endEventDate)
                      ) ||
                      (dayjs().isAfter(dayjs(startEventDate)) && !endEventDate)
                    }
                    hideHours
                  />
                </div>
              )}
            </div>

            <div className="responsive-flex-lg mt-1">
              <div className="text-sm flex">
                <div className="flex items-center mr-4">
                  <Icon name="WorkSiteIcon" className="w-4 h-4 mr-1" />
                  <span className="font-bold">{site?.name}</span>
                </div>
              </div>
              <div className="text-sm flex">
                <div className="flex items-center mr-4">
                  <Icon name="ClockIcon" className="w-4 h-4 mr-1" />
                  <span>{dayjs(publishedDate).format("LL")}</span>
                </div>
              </div>
              {author && author.username && (
                <div className="text-sm flex">
                  <div className="flex items-center mr-4">
                    <Icon name="UserIcon" className="w-4 h-4 text-slate-400" />
                    <span>
                      {t("common.publishedBy", { author: author.username })}
                    </span>
                  </div>
                </div>
              )}
              {pinned && (
                <div className="text-sm flex text-secondary-400">
                  <div className="flex items-center mr-4">
                    <Icon name="TiPinOutline" className="w-4 h-4 mr-1" />
                    <span>{t("common.pinned")}</span>
                  </div>
                </div>
              )}
            </div>

            {/* foot */}

            <div className="flex flex-col gap-2 pt-1 mt-2 border-t border-slate-200">
              <ContactGroups
                contactGroups={[...contactGroupsPublic, ...contactGroups]}
                short
              />

              <div className="text-sm text-gray-500 ">
                {contentRich
                  ? truncateString(getFlattenText(contentRich), 150)
                  : truncateString(content, 150)}
              </div>
            </div>
          </div>
        </div>
      </Link>

      {/* right */}
      <div className="flex flex-col justify-between text-xs w-full lg:border-l lg:pl-4 lg:w-[30em] lg:border-slate-150 gap-2">
        <div className="flex w-full justify-between text-slate-500">
          {status ? <Status status={status} /> : <div />}

          <div className="flex items-center gap-3">
            <div className="flex items-center gap-1">
              <Icon
                name="ChatBubbleLeftRightIcon"
                className="w-4 h-4 text-slate-400"
              />
              {commentsNumber}
            </div>
            <div className="flex items-center gap-1">
              <Icon
                name="HandThumbUpIconSolid"
                className="w-4 h-4 text-slate-400"
              />
              {(upVotes || []).length}
            </div>
          </div>
        </div>
        {lastComment && <CommentItemSmall comment={lastComment} />}

        {item.threads?.length > 0 && (
          <Button
            title={t("threads.threadsCount", {
              count: item.threads?.length,
            })}
            type="primary"
            icon="ChatBubbleLeftRightIcon"
            onClick={() => onOpenThreads(item.id)}
            className="self-end"
          />
        )}

        {canManageItem && (
          <div className="flex w-full gap-1 justify-end">
            <Button
              type="error-line"
              icon="TrashIcon"
              compact
              onClick={() => onDelete(item.id)}
              disabled={!canManageItem}
              confirmMessage={t("actions.confirmDelete")}
            />

            <Button
              title={t("actions.edit")}
              type="warning-line"
              icon="PencilSquareIcon"
              onClick={() => onNavigate(item.id)}
              disabled={!canManageItem}
            />
            <DropDown
              buttonComponent={
                <Icon name="EllipsisVerticalIcon" className="w-4 h-4" />
              }
              items={[
                {
                  label: t("actions.duplicate"),
                  onClick: () => onDuplicate(item.id),
                  icon: "DocumentDuplicateIcon",
                  disabled: !canManageItem,
                },
                {
                  label: pinned ? t("actions.unpin") : t("actions.pin"),
                  onClick: () => onPin(item.id),
                  icon: "TiPinOutline",
                  disabled: !canManageItem,
                },
                {
                  label: t("actions.resend"),
                  onClick: () => onResendNotification(item.id),
                  icon: "BellAlertIcon",
                  disabled: !canManageItem || status !== "online",
                  confirmMessage: t("actions.confirmResend"),
                },
              ]}
              hasActionArrow={false}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default PostItem;
