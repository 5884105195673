import {
  // eslint-disable-next-line comma-dangle
  useCallback,
  useMemo,
  useEffect,
  useContext,
} from "react";
import { useTranslation } from "react-i18next";

import { BellAlertIcon, CalendarDaysIcon } from "@heroicons/react/24/outline";
import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentListIcon,
  ExclamationCircleIcon,
  ListBulletIcon,
  SparklesIcon,
} from "@heroicons/react/20/solid";

import { Post } from "types/Post";
import Header from "common/components/Header/Header";
import useItem from "common/hooks/useItem";
import useRouter from "common/hooks/use-router";
import useList from "common/hooks/useList";
import useAuth from "common/hooks/useAuth";
import SiteSelect from "components/select/SiteSelect/SiteSelect";
import PostTypeSelect from "components/select/AlertTypeSelect/PostTypeSelect";
import FilteredList from "common/components/Table/FilteredList";
import RadioBlock from "common/components/RadioBlock/RadioBlock";
import TabBlockItem from "common/components/TabBlockItem/TabBlockItem";
import WorkSiteIcon from "components/Icon/icons/WorkSiteIcon";
import getDefaultFilters from "common/utils/getDefaultFilters";

import useOrganization from "common/hooks/useOrganization";
import getWording from "common/utils/wording";
import AlertsContext from "common/providers/alerts";
import EmptyBlockWithCTA from "common/components/EmptyBlockWithCTA/EmptyBlockWithCTA";
import ToggleSwitch from "common/components/ToggleSwitch/ToggleSwitch";
import fetchJSON from "common/utils/fetchJSON";
import PostItem from "./PostItem";
import checkRights from "../../common/utils/checkRights";

function EmptyPostDataWithCTA() {
  const { t } = useTranslation();
  return (
    <EmptyBlockWithCTA
      title={t("emptyDatasCta.noSitesYet", { wording: getWording() })}
      content={t("emptyDatasCta.forPosts", { wording: getWording(true) })}
      actionTitle={t("emptyDatasCta.addSite", { wording: getWording() })}
      actionUrl="/sites/add"
    />
  );
}

function Posts() {
  const { t } = useTranslation();
  const { user: currentUser } = useAuth();
  const { organization: currentOrganization } = useOrganization();
  const { setAlert } = useContext(AlertsContext);

  const canSubmitPost = checkRights(
    currentUser,
    "canSubmitPost",
    currentUser?.mySitesId,
    ["isAdmin"]
  );

  const defaultFilters = useMemo(
    () =>
      getDefaultFilters({
        currentUser,
        sitesIdsPath: "site][id",
      }),
    [currentUser]
  );

  const {
    fetchItems,
    items,
    isFetching,
    isItemsValid,
    filters,
    setFilter,
    pageCount,
    setPageIndex,
    pageIndex,
    removeListItem,
    itemsCount,
    itemsPerPage,
    error: errorList1,
    setSorts,
  } = useList<Post>("posts/find-including-site-children", {
    defaultFilters: {
      ...defaultFilters,
      pinned: true,
      site: {
        active: true,
        disabled: false,
      },
    },
    populate: { site: { fields: ["name"] }, author: { fields: ["username"] } },
    defaultSorts: ["publishedDate:DESC", "updatedAt:DESC", "createdAt:DESC"],
  });

  const { items: siteItems } = useList("sites", {
    defaultFilters: currentOrganization?.id
      ? {
          organization: currentOrganization.id,
        }
      : {},
    cachePrefix: `Sites_inPosts_${currentOrganization?.id || "all"}`,
  });

  useEffect(() => {
    if (errorList1) {
      setAlert(errorList1, "danger");
    }
  }, [errorList1, setAlert]);

  const {
    items: dataPosts,
    setFilter: setFilterDataPosts,
    error: errorList2,
  } = useList<Post>("posts/find-including-site-children", {
    defaultFilters: {
      ...defaultFilters,
      site: {
        active: true,
        disabled: false,
      },
    },
    defaultSort: "title:ASC",
    populate: ["site"],
    defaultItemsPerPage: 5000,
  });

  useEffect(() => {
    if (errorList2) {
      setAlert(errorList2, "danger");
    }
  }, [errorList2, setAlert]);

  useEffect(() => {
    setFilter("site][organization", currentOrganization?.id || false);
    setFilterDataPosts("site][organization", currentOrganization?.id || false);
  }, [currentOrganization, setFilter]);

  const statusList = useMemo(() => {
    return [
      {
        id: "",
        name: t("status.all"),
        count: dataPosts?.length || 0,
        icon: ListBulletIcon,
        color: "secondary",
      },
      {
        id: "pending_moderation",
        name: t("status.pending_moderation"),
        count:
          dataPosts?.filter((item: any) => item.status === "pending_moderation")
            ?.length || 0,
        icon: ClipboardDocumentListIcon,
        color: "yellow",
      },
      {
        id: "pending_publication",
        name: t("status.pending_publication"),
        count:
          dataPosts?.filter(
            (item: any) => item.status === "pending_publication"
          )?.length || 0,
        icon: ClipboardDocumentCheckIcon,
        color: "blue",
      },
      {
        id: "online",
        name: t("status.online"),
        count:
          dataPosts?.filter((item: any) => item.status === "online")?.length ||
          0,
        icon: SparklesIcon,
        color: "green",
      },
      {
        id: "offline",
        name: t("status.offline"),
        count:
          dataPosts?.filter((item: any) => item.status === "offline")?.length ||
          0,
        icon: ExclamationCircleIcon,
        color: "gray",
      },
    ];
  }, [dataPosts, t]);

  const { removeItem } = useItem<Post>("posts", "");

  const { navigate } = useRouter();

  const handleNavigate = (id: any) => {
    navigate(`/posts/edit/${id}`);
  };

  const handleDelete = useCallback(
    async (id: any) => {
      try {
        removeListItem(id);
        await removeItem(id);
        fetchItems();
      } catch (e) {
        setAlert(e, "danger");
        return e;
      }
      return true;
    },
    [removeListItem, fetchItems, removeItem, setAlert]
  );

  const handlePin = useCallback(
    async (id: any) => {
      try {
        if (id) {
          await fetchJSON({
            url: `posts/pin/${id}`,
            method: "POST",
          });
          fetchItems();
        }
      } catch (e) {
        console.error(e);
      }
    },
    [fetchItems]
  );

  const handleResendNotification = useCallback(
    async (id: any) => {
      try {
        if (id) {
          await fetchJSON({
            url: `posts/notification/${id}`,
            method: "POST",
          });
          setAlert(t("common.notificationResent"), "success");
        }
      } catch (e) {
        console.error(e);
      }
    },
    [setAlert, t]
  );

  const handleOpenThreads = (id: any) => {
    navigate(`/threads`, { state: { filterOnPost: id } });
  };

  const handleDuplicate = (id: any) => {
    navigate(`/posts/duplicate/${id}`);
  };

  const columns = useMemo(
    () => [
      /* {
        Header: t("filters.status"),
        headerClasses: "hidden",
        columnClasses: "w-full mb-[-8px]", // mb-[-12px]
        filterName: "status$contains",
        filterComponent: (props: any) => (
          <RadioBlock
            Component={TabBlockItem}
            items={statusList}
            horizontal
            noGap
            {...props}
          />
        ),
      }, */
      {
        Header: t("forms.title"),
        filterName: "title$contains",
        filterLabel: t("filters.search"),
        columnClasses: "md:flex-1",
      },
      {
        Header: t("forms.type"),
        filterName: "type][id",
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => (
          <PostTypeSelect isFilter Icon={BellAlertIcon} {...props} />
        ),
      },
      {
        Header: t("forms.site", {
          wording: getWording(false, true),
        }),
        filterName: "site][id",
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => (
          <SiteSelect
            isFilter
            allowReadOnlyOptions
            Icon={WorkSiteIcon}
            {...props}
          />
        ),
      },
      {
        Header: t("forms.pinned"),
        filterName: "pinned",
        filterComponent: (props: any) => (
          <div className="pt-2">
            <ToggleSwitch rightLabel={t("forms.pinned")} {...props} />
          </div>
        ),
        // headerClasses: "mb-3",
        columnClasses: "md:flex-1",
      },
    ],
    [t]
  );

  const setOverallFilter = useCallback(
    (key: string, value: string | number | boolean) => {
      setFilter(key, value);
      // setFilterDataPosts(key, value);
      if (key === "status$contains") {
        if (value === "pending_moderation" || value === "pending_publication") {
          setSorts(["publishedDate:ASC", "updatedAt:DESC", "createdAt:DESC"]);
        } else {
          setSorts(["publishedDate:DESC", "updatedAt:DESC", "createdAt:DESC"]);
        }
      }
    },
    [setFilter, setSorts]
  );

  const isBlank = !isItemsValid && !isFetching;

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500">
      <Header
        title={t("titlePages.posts")}
        // subtitle={t("titleLegends.posts")}
        btnTitle={t("newItem.post")}
        btnSubtitle={t("newItem.postLegend")}
        Icon={CalendarDaysIcon}
        addLink="/posts/add"
        addLinkDisabled={!canSubmitPost || Boolean(!siteItems?.length)}
        isBeforeFilters
        filters={filters}
        setFilter={setOverallFilter}
        filterName="status$contains"
        FilterComponent={(props: any) => (
          <RadioBlock
            Component={TabBlockItem}
            items={statusList}
            horizontal
            noGap
            {...props}
          />
        )}
      />

      <FilteredList
        data={items ?? []}
        columns={columns} // used as filter items
        isFetching={isFetching}
        pageCount={pageCount}
        filters={filters}
        setFilter={setOverallFilter}
        setPageIndex={setPageIndex}
        pageIndex={pageIndex}
        RenderItem={PostItem}
        RenderEmptyBlock={
          !siteItems?.length && !isBlank ? EmptyPostDataWithCTA : undefined
        }
        onDelete={handleDelete}
        onNavigate={handleNavigate}
        onDuplicate={handleDuplicate}
        onPin={handlePin}
        onResendNotification={handleResendNotification}
        itemsCount={itemsCount}
        itemsPerPage={itemsPerPage}
        onOpenThreads={handleOpenThreads}
        pinnedSection={filters?.pinned === true}
      />
    </div>
  );
}

export default Posts;
