import getWording from "common/utils/wording";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-extraneous-dependencies
import Chart from "react-apexcharts";
import { useMemo } from "react";
import Icon from "components/Icon/Icon";
import fr from "apexcharts/dist/locales/fr.json";

export default function ThreadStatistics({
  data,
  isDetail,
}: {
  data: any;
  isDetail: boolean;
}) {
  const { t } = useTranslation();

  const stats = useMemo(
    () => [
      {
        name: t("stats.nbTotalThreads", { wording: getWording() }),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat: data && data.nbThreads ? data.nbThreads : 0,
      },
      {
        name: t("stats.nbThreadsAnswered", { wording: getWording() }),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat: data && data.nbThreadsAnswered ? data.nbThreadsAnswered : 0,
      },
      {
        name: t("stats.nbThreadsWaitingAnswer", { wording: getWording() }),
        icon: <Icon className="w-4 h-4" name="WorkSiteIcon" />,
        stat:
          data && data.nbThreadsWaitingAnswer ? data.nbThreadsWaitingAnswer : 0,
      },
    ],
    [data, t]
  );

  const threadsPerSite = {
    datasets:
      data?.threadsPerSite?.map((thread: { site: string; data: any[] }) => ({
        name: thread.site,
        data: thread?.data?.map((d) => ({
          x: d.date,
          y: d.value,
        })),
        borderWidth: 1,
      })) ?? [],
  };

  const threadsPerType = {
    labels: Object.keys(data?.threadsPerType || {}),
    datasets: [
      {
        label: t("stats.siteTypes"),
        data: Object.values(data?.threadsPerType || {}),
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <dl className="mt-3 grid grid-cols-1 lg:grid-cols-3 gap-3 mb-3">
        {stats.map((item) => (
          <div
            key={item?.name}
            className="relative bg-white pt-3 px-2 sm:pt-2 sm:px-2 shadow rounded-lg overflow-hidden"
          >
            <dt>
              <div className="absolute bg-indigo-500 rounded-md p-3">
                {item?.icon}
              </div>
              <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                {item?.name}
              </p>
            </dt>
            <dd className="ml-16 pb-2 flex items-baseline sm:pb-3">
              <p className="text-2xl font-semibold text-gray-900">
                {item?.stat}
              </p>
            </dd>
          </div>
        ))}
      </dl>
      <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-3">
        <div className="h-80 justify-center white-box">
          <Chart
            options={{
              chart: {
                id: "threadsBySite",
                locales: [fr],
                defaultLocale: "fr",
              },
              title: {
                text: t("stats.threadsBySite", { wording: getWording() }),
                align: "center",
                margin: 18,
                style: {
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#263238",
                },
              },
              legend: {
                position: "bottom",
              },
              noData: {
                text: "Aucune données à afficher",
                align: "center",
                verticalAlign: "middle",
                offsetX: 0,
                offsetY: 0,
                style: {
                  fontSize: "18px",
                  color: "#263238",
                },
              },
            }}
            series={threadsPerSite.datasets}
            height="100%"
            type="bar"
          />
        </div>
        <div className="h-80 justify-center white-box">
          <Chart
            options={{
              chart: {
                id: "threadsByType",
                locales: [fr],
                defaultLocale: "fr",
              },
              stroke: {
                curve: "straight",
              },
              title: {
                text: t("stats.threadsByType"),
                align: "center",
                margin: 18,
                style: {
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#263238",
                },
              },
              legend: {
                position: "bottom",
              },
              labels: threadsPerType.labels,
              xaxis: {
                type: "datetime",
              },
              yaxis: {
                tickAmount: 5,
                labels: {
                  formatter: (value) => Math.round(value).toString(),
                },
              },
              noData: {
                text: "Aucune données à afficher",
                align: "center",
                verticalAlign: "middle",
                offsetX: 0,
                offsetY: 0,
                style: {
                  fontSize: "18px",
                  color: "#263238",
                },
              },
            }}
            series={
              data && data.threadsPerType
                ? (Object.values(data.threadsPerType || {}) as number[])
                : []
            }
            height="100%"
            type="pie"
          />
        </div>
        {isDetail && (
          <div className="h-80 justify-center white-box">
            <Chart
              options={{
                chart: {
                  id: "newSitesByDate",
                  locales: [fr],
                  defaultLocale: "fr",
                },
                stroke: {
                  curve: "straight",
                },
                title: {
                  text: t("stats.newThreadsBySite", {
                    wording: getWording(false, false),
                  }),
                  align: "center",
                  margin: 18,
                  style: {
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#263238",
                  },
                },
                legend: {
                  position: "bottom",
                },
                xaxis: {
                  type: "datetime",
                },
                yaxis: {
                  tickAmount: 5,
                  labels: {
                    formatter: (value) => Math.round(value).toString(),
                  },
                },
                noData: {
                  text: "Aucune données à afficher",
                  align: "center",
                  verticalAlign: "middle",
                  offsetX: 0,
                  offsetY: 0,
                  style: {
                    fontSize: "18px",
                    color: "#263238",
                  },
                },
              }}
              series={threadsPerSite.datasets}
              height="100%"
              type="line"
            />
          </div>
        )}
      </div>
    </>
  );
}
