import {
  BellAlertIcon,
  ChartBarIcon,
  // TagIcon,
} from "@heroicons/react/24/outline";
import Select from "common/components/Select/Select";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import HeaderWithRightCustomSection from "common/components/Header/HeaderWithRightCustomSection";
import Filters from "common/components/Header/Filters";
import SiteSelect from "components/select/SiteSelect/SiteSelect";
import getWording from "common/utils/wording";
import WorkSiteIcon from "components/Icon/icons/WorkSiteIcon";
import PostSelect from "components/select/PostSelect/PostSelect";
import PostTypeSelect from "components/select/AlertTypeSelect/PostTypeSelect";
import DatePicker from "common/components/DatePicker/DatePicker";
import useOrganization from "common/hooks/useOrganization";
import fetchJSON from "common/utils/fetchJSON";
import ThreadTypeSelect from "components/select/ThreadTypeSelect/ThreadTypeSelect";
// import TagSelect from "components/select/TagSelect/TagSelect";
import { add, format } from "date-fns";
import PageLoading from "common/components/PageLoading/PageLoading";
import PostStatistics from "./PostStatistics";
import ThreadStatistics from "./ThreadStatistics";
import SiteStatistics from "./SiteStatistics";
import OrganizationStatistics from "./OrganizationStatistics";

export default function Stats() {
  const { t } = useTranslation();
  const [statsToDisplay, setStatsToDisplay] = useState<
    "sites" | "posts" | "threads" | "organizations"
  >("organizations");
  const [filters, setFilters] = useState<any>({});
  const { organization } = useOrganization();

  const [data, setData] = useState<any>({});
  const [isFetching, setIsFetching] = useState<boolean>(true);

  // Convert filters into query string
  const buildQueryParams = (f: any) => {
    return Object.keys(f)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(f[key])}`)
      .join("&");
  };

  // Make the url depending on filters and statsToDisplay
  const buildUrl = useMemo(() => {
    if (!statsToDisplay || !organization?.id) return null;

    const baseUrl = `${statsToDisplay}/stats`;
    const filterParams = buildQueryParams(filters);

    return `${baseUrl}/${organization.id}/${
      filterParams ? `?${filterParams}` : ""
    }`;
  }, [organization, statsToDisplay, filters]);

  useEffect(() => {
    let isCancelled = false;

    if (buildUrl) {
      const getData = async () => {
        setIsFetching(true);
        try {
          const res = await fetchJSON({
            url: buildUrl,
            method: "GET",
          });
          if (!isCancelled) {
            setData(res);
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des données :", error);
        } finally {
          if (!isCancelled) {
            setIsFetching(false);
          }
        }
      };

      getData();
    }

    return () => {
      isCancelled = true; // On signale que cet effet est annulé
    };
  }, [buildUrl]);

  const handleChangeFilter = (filterName: string, value: string) => {
    if (value && value !== null) {
      setFilters((prev: any) => ({ ...prev, [filterName]: value }));
    } else {
      setFilters((prev: any) => {
        const newFilters = { ...prev };
        delete newFilters[filterName];
        return newFilters;
      });
    }
  };

  const handleStartDateChange = useCallback((date: Date) => {
    let ldate = date;
    if (ldate !== null) {
      ldate = add(ldate, { hours: 12 });
    }
    handleChangeFilter("startDate", format(ldate, "yyyy-MM-dd"));
    if (date === null) {
      handleChangeFilter("endDate", date);
    }
  }, []);

  // Filters columns
  const columns = useMemo(() => {
    const defaultColumns = [
      {
        Header: t("forms.startDate", {
          wording: getWording(false, true),
        }),
        filterName: "startDate",
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => (
          <DatePicker
            showTimeSelect={false}
            {...props}
            onChange={handleStartDateChange}
          />
        ),
      },
      {
        Header: t("forms.endDate", {
          wording: getWording(false, true),
        }),
        filterName: "endDate",
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => (
          <DatePicker
            showTimeSelect={false}
            minDate={filters?.startDate}
            disabled={!filters?.startDate}
            {...props}
          />
        ),
      },
      {
        Header: t("stats.selectInterval", {
          wording: getWording(false, true),
        }),
        filterName: "scale",
        columnClasses: "md:flex-1",
        filterComponent: (props: any) => (
          <Select
            options={[
              { value: "month", label: t("stats.month") },
              { value: "week", label: t("stats.week") },
              { value: "day", label: t("stats.day") },
            ]}
            clearable={false}
            {...props}
          />
        ),
      },
    ];

    switch (statsToDisplay) {
      case "sites":
        return [
          {
            Header: t("forms.site", {
              wording: getWording(false, true),
            }),
            filterName: "site",
            columnClasses: "md:flex-1",
            filterComponent: (props: any) => (
              <SiteSelect
                isFilter
                allowReadOnlyOptions
                Icon={WorkSiteIcon}
                {...props}
              />
            ),
          },
          ...defaultColumns,
        ];

      case "posts":
        return [
          {
            Header: t("item.post", {
              wording: getWording(false, true),
            }),
            filterName: "post",
            columnClasses: "md:flex-1",
            filterComponent: (props: any) => (
              <PostSelect
                isFilter
                filterOnSiteId={filters.site}
                Icon={WorkSiteIcon}
                {...props}
              />
            ),
          },
          {
            Header: t("forms.type"),
            filterName: "type",
            columnClasses: "md:flex-1",
            filterComponent: (props: any) => (
              <PostTypeSelect isFilter Icon={BellAlertIcon} {...props} />
            ),
          },
          {
            Header: t("forms.site", {
              wording: getWording(false, true),
            }),
            filterName: "site",
            columnClasses: "md:flex-1",
            filterComponent: (props: any) => (
              <SiteSelect
                isFilter
                allowReadOnlyOptions
                Icon={WorkSiteIcon}
                {...props}
              />
            ),
          },
          ...defaultColumns,
        ];
      case "threads":
        return [
          {
            Header: t("forms.site", {
              wording: getWording(false, true),
            }),
            filterName: "site",
            columnClasses: "md:flex-1",
            filterComponent: (props: any) => (
              <SiteSelect
                isFilter
                allowReadOnlyOptions
                Icon={WorkSiteIcon}
                {...props}
              />
            ),
          },
          {
            Header: t("titlePages.posts"),
            filterName: "post",
            columnClasses: "md:flex-1",
            filterComponent: (props: any) => (
              <PostSelect
                isFilter
                filterOnSiteId={filters.site}
                Icon={WorkSiteIcon}
                {...props}
              />
            ),
          },
          {
            Header: t("forms.type"),
            filterName: "type",
            columnClasses: "md:flex-1",
            filterComponent: (props: any) => (
              <ThreadTypeSelect isFilter Icon={BellAlertIcon} {...props} />
            ),
          },
          ...defaultColumns,
        ];
      default:
        return defaultColumns;
    }
  }, [
    filters.site,
    filters?.startDate,
    handleStartDateChange,
    statsToDisplay,
    t,
  ]);

  const renderStatsContent = () => {
    switch (statsToDisplay) {
      case "sites":
        return <SiteStatistics data={data} isDetail={filters.site} />;
      case "posts":
        return <PostStatistics data={data} isDetail={filters.post} />;
      case "threads":
        return (
          <ThreadStatistics
            data={data}
            isDetail={filters.site || filters.post || filters.type}
          />
        );
      default:
        return <OrganizationStatistics data={data} />;
    }
  };

  const handleChangeStatsToDisplayValue = (
    value: "sites" | "posts" | "threads"
  ) => {
    if (value === null) {
      setStatsToDisplay("organizations");
    } else {
      setStatsToDisplay(value);
    }
    setFilters({});
  };

  return (
    <div className="py-6 h-full">
      <div className="mx-auto text-sm max-w-full h-full max-main-box px-4 mb-4">
        <HeaderWithRightCustomSection
          title={t("titlePages.stats")}
          subtitle={t("titleLegends.stats")}
          Icon={ChartBarIcon}
          rightSection={
            <Select
              placeholder={t(`stats.selectDataToDisplay`)}
              onChange={handleChangeStatsToDisplayValue}
              options={[
                { value: "sites", label: getWording(true, true) },
                { value: "posts", label: "Publications" },
                { value: "threads", label: "Échanges" },
              ]}
              value={statsToDisplay}
            />
          }
        />
        <Filters
          columns={columns}
          filters={filters}
          handleChangeFilter={handleChangeFilter}
        />
        {isFetching ? (
          <div className="w-full fixed left-72 max-main-box top-thirty-vh">
            <PageLoading customStyles="absolute top-[75px] left-[calc(50%-100px)] w-[200px] rounded-full h-16 mx-auto flex justify-center items-center bg-slate-100/80 z-10" />
          </div>
        ) : (
          renderStatsContent()
        )}
      </div>
    </div>
  );
}
