export const checkEmail = (value: string, t: any, isRequired = true) => {
  const isMailCorrect = (mail: string | undefined) => {
    if (!mail && isRequired) return t("common.fieldRequired");

    const isCorrect = mail?.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    return isCorrect;
  };

  if (value?.length) {
    return isMailCorrect(value) ? undefined : t("forms.badEmail");
  }
  return t("common.fieldRequired");
};

export const checkPhoneFormat = (value: string, t: any) => {
  const userNum = value.replace(/^(\+|00)330?/, "0"); // "0033 6", "+336" => 06

  const isMobilePhone = userNum.match(/^(06|07)[0-9]{8}$/);
  const isFixPhone = userNum.match(/^(01|02|03|04|05|08|09)[0-9]{8}$/);

  return !isMobilePhone && !isFixPhone ? t("forms.badPhone") : undefined;
};
