import classNames from "classnames";

type DividerProps = {
  color?: string;
  margin?: string;
};

function Divider({ color = "bg-slate-300", margin = "my-4" }: DividerProps) {
  const dividerClass = classNames(`w-100 h-px ${color} ${margin}`);
  return <div className={dividerClass} />;
}

export default Divider;
