import { useCallback, useState } from "react";
import Modal from "common/components/Modal/Modal";
import { useTranslation } from "react-i18next";
import fetchJSON from "common/utils/fetchJSON";
import { readRemoteFile } from "react-papaparse";
import SectionTitle from "../SectionTitle/SectionTitle";
import FileUploader from "../FileUploader/FileUploader";
import ImageFromStrapiMedia from "../ImageFromStrapiMedia/ImageFromStrapiMedia";

export type ImportModalProps = {
  confirmModal: (values: any) => void;
  closeModal: () => void;
  visible?: boolean;
  onDownloadExample?: () => void;
};

// interface Mapping {
//   [key: string]: string;
// }

// interface PreviewData {
//   headers: string[];
//   rows: any[];
// }

const typeOptions = [
  { name: "users" },
  { name: "signalements" },
  { name: "comments" },
  { name: "userFollowedSite" },
  { name: "posts" },
];

function ImportModal({
  confirmModal,
  closeModal,
  visible = true,
  onDownloadExample,
}: ImportModalProps) {
  const { t } = useTranslation();
  const [entityType, setEntityType] = useState("users");
  const [fileToImport, setFileToImport] = useState<any[]>();

  const handleFileUploaded = useCallback(async (file: any) => {
    // count the number of columns in first row (line) of the imported CSV file
    const fileUrl = ImageFromStrapiMedia(file).uri;
    readRemoteFile(fileUrl, {
      header: true,
      preview: 1,
      skipEmptyLines: true,
      download: true,
      complete: async (result: any) => {
        setFileToImport(file);
      },
    });
  }, []);

  const handleUpload = async () => {
    if (!fileToImport || !entityType) {
      return;
    }

    // TODO : catch la réponse et afficher une popup de succès ou erreur
    try {
      await fetchJSON({
        url: `import/${entityType}`,
        method: "POST",
        payload: { data: { file: fileToImport } },
      });
    } catch (error) {
      console.error("Upload error:", error);
    }
  };

  return (
    <Modal
      confirmBtnLabel={t("import.createBtn")}
      disableConfirmBtn={false}
      // onConfirmModal={nbImported > -1 ? confirmModal : handleUpload}
      onCloseModal={closeModal}
      visible={visible}
      onConfirmModal={handleUpload}
    >
      <div style={{ height: "100%", overflowY: "scroll", width: "100%" }}>
        {/* {isImporting && <PageLoading />} */}

        <div className="flex flex-col w-full gap-4 mb-6">
          <SectionTitle /* icon="UploadIcon" */ title={t("import.contacts")} />
          <p className="flex-1 text-left w-full font-bold">
            Sélectionnez le type d&apos;entité à importer :
          </p>
          <select
            onChange={(e) => setEntityType(e.target.value)}
            value={entityType}
          >
            {typeOptions &&
              typeOptions?.map((type: any) => (
                <option value={type.name}>{type.name}</option>
              ))}
          </select>
          <FileUploader
            max={1}
            onUpload={handleFileUploaded}
            canRenameFiles={false}
            accept=".xlsx, .xls, .csv"
          />

          {onDownloadExample && (
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border enabled:border-transparent enabled:border-yellow-600 shadow-sm px-4 py-2 disabled:bg-gray-50 text-base font-medium enabled:text-yellow-600 enabled:hover:bg-yellow-600 enabled:hover:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:col-start-1 sm:text-sm"
              onClick={onDownloadExample}
            >
              {t("export.downloadExample")}
            </button>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default ImportModal;
