import { useCallback, useMemo, useState } from "react";
import Modal from "common/components/Modal/Modal";
import { useTranslation } from "react-i18next";
import { ContactGroup } from "types/ContactGroup";
import useOrganization from "common/hooks/useOrganization";
import fetchJSON from "common/utils/fetchJSON";
import { usePapaParse } from "react-papaparse";
import getWording from "common/utils/wording";
import SectionTitle from "../SectionTitle/SectionTitle";
import { FormInput } from "../FormInput/FormInput";
import FileUploader from "../FileUploader/FileUploader";
import ImageFromStrapiMedia from "../ImageFromStrapiMedia/ImageFromStrapiMedia";
import { RemoteSelect } from "../RemoteSelect/RemoteSelect";
import PageLoading from "../PageLoading/PageLoading";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import Divider from "../Divider/Divider";

export type ImportContactsModalProps = {
  confirmModal: (values: any) => void;
  closeModal: () => void;
  visible?: boolean;
};

type ContactInfo = {
  name: string;
  email?: string;
  address?: string | string;
  phoneNumber?: string;
  quality?: string;
  [key: string]: any;
};

type UploadResult = {
  alreadyExistCount: number;
  successCount: number;
  ignoredEntities: any[];
  modifiedContacts: any[];
};

const formFields = [
  { key: "name", label: "forms.name", multiple: true },
  { key: "email", label: "forms.email" },
  { key: "phoneNumber", label: "forms.phoneNumber" },
  { key: "quality", label: "forms.quality" },
  { key: "address", label: "forms.address", multiple: true },
];

type KeyAssignComponentProps = {
  label: string;
  name: string;
  value: any | undefined;
  options: { value: string; label: string }[];
  example: string;
  multiple?: boolean;
  onChange: (value: string) => void;
};

function KeyAssignComponent({
  label,
  name,
  value,
  options,
  example,
  multiple = false,
  onChange,
}: KeyAssignComponentProps) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <p className="flex-1 font-bold text-left w-full">{t(label)}</p>
      <div className="flex-1">
        <FormInput
          type="select"
          name={name}
          label={t(label)}
          value={value}
          options={options}
          onChange={onChange}
          multiple={multiple}
        />
      </div>
      <div className="flex flex-row items-center w-full gap-4">
        <p className="text-sm text-left font-bold">Exemple :</p>
        <p className="flex-[2] text-left text-xs w-full">{example}</p>
      </div>
    </div>
  );
}

function ImportContactsModal({
  confirmModal,
  closeModal,
  visible = true,
}: ImportContactsModalProps) {
  const { t } = useTranslation();
  const { organization } = useOrganization();
  const { readRemoteFile } = usePapaParse();

  const [fileToImport, setFileToImport] = useState<any[]>();
  const [columnNames, setColumnNames] = useState<string[]>([]);
  const [contactGroupId, setContactGroupId] = useState(0);
  const [siteId, setSiteId] = useState(0);
  const [columns, setColumns] = useState<ContactInfo>({
    name: "",
  });
  const [recordExample, setRecordExample] = useState<any>({});
  const [isImporting, setIsImporting] = useState(false);
  const [nbImported, setNbImported] = useState(-1);
  const [uploadResult, setUploadResult] = useState<UploadResult>();
  const [allowModifyContactWithImport, setAllowModifyContactWithImport] =
    useState(false);

  const isInputDataValid = useMemo(() => {
    return fileToImport && columns.name && !isImporting;
  }, [fileToImport, columns, isImporting]);

  const handleCsvContent = useCallback(async (result: any) => {
    try {
      // parse results
      const records = result.data;

      if (records.length > 0) {
        // count the columns
        const colNames = Object.keys(records[0]);

        setRecordExample(records[0]);

        setColumnNames(colNames);

        setColumns({
          name: colNames.includes("name") ? "name" : "",
          email: colNames.includes("email") ? "email" : "",
          phoneNumber: colNames.includes("phoneNumber") ? "phoneNumber" : "",
          quality: colNames.includes("quality") ? "quality" : "",
          address: colNames.includes("address") ? "address" : "",
        });
      } else {
        console.error("No data to import or bad CSV format");
      }
    } catch (e) {
      console.error(e);
    }
  }, []);

  const handleFileUploaded = useCallback(
    async (file: any) => {
      // count the number of columns in first row (line) of the imported CSV file
      const fileUrl = ImageFromStrapiMedia(file).uri;
      readRemoteFile(fileUrl, {
        header: true,
        preview: 1,
        skipEmptyLines: true,
        download: true,
        complete: async (result: any) => {
          await handleCsvContent(result);
          setFileToImport(file);
        },
      });
    },
    [handleCsvContent, readRemoteFile]
  );

  const processOptions = (options: ContactGroup[]) => {
    const list = options.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

    return list;
  };

  const handleUpload = async () => {
    try {
      setIsImporting(true);
      const res: any = await fetchJSON({
        url: `contacts/import`,
        method: "POST",
        payload: {
          data: {
            file: fileToImport,
            contactGroupId,
            columns,
            organizationId: organization?.id,
            allowModifyContactWithImport,
          },
        },
      });
      setNbImported(res?.successCount || 0);
      setUploadResult(res);
    } catch (e) {
      console.error(e);
    } finally {
      setIsImporting(false);
    }
  };

  const handleChangeAllowModifyContactWithImport = () => {
    setAllowModifyContactWithImport(!allowModifyContactWithImport);
  };

  return (
    <Modal
      confirmBtnLabel={
        !fileToImport || nbImported > -1 ? undefined : t("import.createBtn")
      }
      disableConfirmBtn={!isInputDataValid}
      onConfirmModal={nbImported > -1 ? confirmModal : handleUpload}
      onCloseModal={closeModal}
      visible={visible}
    >
      <div style={{ height: "100%", overflowY: "scroll", width: "100%" }}>
        {isImporting && <PageLoading />}
        {nbImported > -1 ? (
          <div className="flex flex-col w-full gap-4 mb-6">
            <SectionTitle /* icon="UploadIcon" */ title={t("import.success")} />
            <div className="flex flex-col w-full gap-4 items-center">
              <p className="flex-1 text-center w-full">
                {t("import.successMessage", {
                  count: nbImported,
                })}
              </p>
              {uploadResult?.modifiedContacts &&
                uploadResult?.modifiedContacts.length > 0 && (
                  <div>
                    <p className="flex-1 text-center w-full">
                      {t("import.modifiedContacts", {
                        count: uploadResult?.modifiedContacts.length,
                      })}
                    </p>
                    <div className="flex flex-col gap-2 items-center mt-4">
                      <table className="table-auto border-collapse border border-gray-200">
                        <thead>
                          <tr>
                            {Object.keys(
                              uploadResult?.modifiedContacts[0] || {}
                            ).map((key) => (
                              <th
                                key={key}
                                className="border border-gray-200 px-4 py-2"
                              >
                                {key}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {uploadResult?.modifiedContacts.map((entity) => (
                            <tr key={entity.name}>
                              {Object.entries(entity).map(([key, value]) => (
                                <td
                                  key={key}
                                  className="border border-gray-200 px-4 py-2"
                                >
                                  {value as string}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              {uploadResult?.ignoredEntities &&
                uploadResult?.ignoredEntities.length > 0 && (
                  <div>
                    <p className="flex-1 text-center w-full">
                      {t("import.ignoredContacts", {
                        count: uploadResult?.ignoredEntities.length,
                      })}
                    </p>
                    <div className="flex flex-col gap-2 items-center mt-4">
                      <table className="table-auto border-collapse border border-gray-200">
                        <thead>
                          <tr>
                            {Object.keys(
                              uploadResult?.ignoredEntities[0] || {}
                            ).map((key) => (
                              <th
                                key={key}
                                className="border border-gray-200 px-4 py-2"
                              >
                                {key}
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {uploadResult?.ignoredEntities.map((entity) => (
                            <tr key={entity.name}>
                              {Object.entries(entity).map(([key, value]) => (
                                <td
                                  key={key}
                                  className="border border-gray-200 px-4 py-2"
                                >
                                  {value as string}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-col w-full gap-4 mb-6">
              <SectionTitle
                /* icon="UploadIcon" */ title={t("import.contacts")}
              />
              <FileUploader
                max={1}
                onUpload={handleFileUploaded}
                canRenameFiles={false}
              />
              {/* TODO Rajouter le bouton pour dl le template */}
            </div>
            {columnNames.length > 0 && (
              <div className="flex flex-col w-full gap-1 mb-6">
                {/* <div className="flex flex-row gap-4 items-center justify-end">
                  <p className="basis-1/2 font-bold text-sm text-left pl-4">
                    {t("import.row2example")}
                  </p>
                </div> */}
                <p className="flex-1 text-left w-full font-bold">
                  {t("import.selectKeyAssignation")}
                </p>
                <div className="grid grid-cols-2 gap-4">
                  {formFields.map((field) => (
                    <KeyAssignComponent
                      key={field.key}
                      label={field.label}
                      name={`${field.key}Column`}
                      value={columns[field.key]}
                      options={columnNames.map((colName) => ({
                        value: colName,
                        label: colName,
                      }))}
                      example={
                        Array.isArray(columns[field.key])
                          ? columns[field.key]
                              .map(
                                (colName: string | number) =>
                                  recordExample?.[colName]
                              )
                              .join(" ")
                          : recordExample?.[columns[field.key] || ""]
                      }
                      multiple={field.multiple}
                      onChange={(value: string) =>
                        setColumns({ ...columns, [field.key]: value })
                      }
                    />
                  ))}
                </div>
                <Divider />

                {/* Note : le selecteur de site n'est pas nécessaire,
                mais comme il peut y avoir beaucoup de groupes de contacts
                sur beaucoup de sites différents, ça permet de filtrer */}
                <div className="flex flex-col gap-2 items-center">
                  <p className="flex-1 text-left w-full font-bold">
                    {t("import.selectSite", {
                      wording: getWording(false, false),
                    })}{" "}
                    :
                  </p>
                  <RemoteSelect
                    url="sites"
                    filters={[
                      { name: "organization", value: organization?.id },
                    ]}
                    processOptions={processOptions}
                    onChange={(val: any) => setSiteId(val)}
                  />
                </div>
                <Divider />
                <div className="flex flex-col gap-2 items-center">
                  <p className="flex-1 text-left w-full font-bold">
                    {t("import.selectContactGroup")} :
                  </p>
                  <RemoteSelect
                    url="contact-groups"
                    filters={[
                      { name: "site][organization", value: organization?.id },
                      { name: "site][id", value: siteId || undefined },
                    ]}
                    processOptions={processOptions}
                    onChange={setContactGroupId}
                  />
                </div>
                <Divider />
                <div className="flex flex-col gap-2">
                  <div className="pt-2">
                    <ToggleSwitch
                      rightLabel={t("import.modifyWithImport")}
                      value={allowModifyContactWithImport}
                      onChange={handleChangeAllowModifyContactWithImport}
                    />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </Modal>
  );
}

export default ImportContactsModal;
