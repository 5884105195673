/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import { SyntheticEvent, useCallback, useEffect, useState } from "react";
import "react-image-crop/dist/ReactCrop.css";
import Modal from "common/components/Modal/Modal";
import ReactCrop from "react-image-crop";

export type CropModalProps = {
  tmpFiles: Array<any>;
  setTmpFiles: (value: any) => void;
  confirmModal: (value: any) => void;
  closeModal: () => void;
  visible?: boolean;
};

const CANVAS_SIZE = 500; // Taille fixe du canvas

function CropModal({
  tmpFiles,
  setTmpFiles,
  confirmModal,
  closeModal,
  visible = true,
}: CropModalProps) {
  const [canvasUrls, setCanvasUrls] = useState<string[]>([]);

  const generateCanvasImage = (image: HTMLImageElement, aspect: number) => {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) return null;

    canvas.width = CANVAS_SIZE * aspect;
    canvas.height = CANVAS_SIZE;
    console.log({ w: canvas.width, h: canvas.height, aspect });

    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const scale = Math.min(
      canvas.width / image.width,
      canvas.height / image.height
    );
    const newWidth = image.width * scale;
    const newHeight = image.height * scale;
    const offsetX = (canvas.width - newWidth) / 2;
    const offsetY = (canvas.height - newHeight) / 2;

    ctx.drawImage(image, offsetX, offsetY, newWidth, newHeight);

    return canvas.toDataURL("image/png");
  };

  useEffect(() => {
    const newCanvasUrls = tmpFiles.map((file) => {
      if (file.image) {
        return generateCanvasImage(file.image, file.crop.aspect);
      }
      return file.url;
    });
    setCanvasUrls(newCanvasUrls);
  }, [tmpFiles]);

  const handleImageLoaded = useCallback(
    (file: any, image: HTMLImageElement) => {
      file.image = image; // Stocker l'image originale
      const newCanvasUrl = generateCanvasImage(image, file.crop.aspect);
      setCanvasUrls((prevUrls) => {
        const updatedUrls = [...prevUrls];
        updatedUrls[file.index] = newCanvasUrl || file.url;
        return updatedUrls;
      });
    },
    []
  );

  const handleCropChange = useCallback(
    (index: number, crop: any) => {
      tmpFiles[index].crop = { ...crop, aspect: tmpFiles[index].crop.aspect };
      setTmpFiles([...tmpFiles]);
    },
    [tmpFiles, setTmpFiles]
  );

  const getCroppedImage = (imageSrc: string, crop: any) => {
    return new Promise<string>((resolve) => {
      const image = new Image();
      image.src = imageSrc;
      image.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        if (!ctx) return;

        canvas.width = crop.width;
        canvas.height = crop.height;

        ctx.drawImage(
          image,
          crop.x,
          crop.y,
          crop.width,
          crop.height,
          0,
          0,
          crop.width,
          crop.height
        );

        canvas.toBlob((blob) => {
          if (blob) {
            resolve(URL.createObjectURL(blob));
          }
        }, "image/png");
      };
    });
  };

  const handleConfirm = async () => {
    const updatedFiles = await Promise.all(
      tmpFiles.map(async (file, index) => {
        if (!file.crop || !canvasUrls[index]) return file;
        const croppedImage = await getCroppedImage(
          canvasUrls[index],
          file.crop
        );
        return { ...file, croppedUrl: croppedImage };
      })
    );

    confirmModal(updatedFiles);
  };

  return (
    <Modal
      onConfirmModal={handleConfirm}
      onCloseModal={closeModal}
      visible={visible}
    >
      <div style={{ height: "100%", width: "500px", overflowY: "auto" }}>
        {canvasUrls.map((canvasUrl, key) => (
          <ReactCrop
            key={key}
            crop={tmpFiles[key]?.crop}
            onChange={(newCrop: any) =>
              handleCropChange(tmpFiles[key].index, newCrop)
            }
            aspect={tmpFiles[key].crop.aspect}
          >
            <img
              alt="crop"
              src={canvasUrl}
              onLoad={(event: SyntheticEvent<HTMLImageElement, Event>) =>
                handleImageLoaded(tmpFiles[key], event.currentTarget)
              }
            />
          </ReactCrop>
        ))}
      </div>
    </Modal>
  );
}

export default CropModal;
